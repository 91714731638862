<script setup lang="ts">
import { signInWithGoogle } from '@/firebase/functions';
import LogoDark from '@/layouts/full/logo/LogoLight.vue';
import { ref } from 'vue';

const checkbox = ref(false);

function connectWithGoogle() {
    checkbox.value = true;
    console.log('connect with google');
    signInWithGoogle().then(() => {
        console.log('signed in with google');
        checkbox.value = false;
    });
}


</script>

<template >
    <div class="hero">
        <v-container style="height: 100vh;" fluid>

            <v-card width="100%" style="height: 100vh;" class="pa-5 mb-5 d-flex justify-center" elevation="10"
                color="transparent">
                <div class="align-self-center">
                    <div style="max-width: 100px;">
                        <LogoDark style="width: 100%; justify: center;" class="mb-10"></LogoDark>
                    </div>

                    <h1 style="color: white;">Connect Wallet</h1>

                    <div class="d-flex mt-5 mb-5">
                        <v-btn v-if="checkbox" icon color="secondary" size="x-small" @click="checkbox = !checkbox"><v-icon
                                color="#222222">mdi-check</v-icon></v-btn>
                        <v-btn v-else icon color="grey" size="x-small" @click="checkbox = !checkbox"></v-btn>
                        <div class="align-self-center ml-3">
                            <p style="color: white; font-size: 16px;">By connecting wallet, I agree to the
                                <a style="color: #D3FFB0; font-weight: 700;" target="_blank"
                                    href="https://equil-labs.notion.site/EQLX-Terms-Conditions-6005ccadca5f427aaf9af0e0dfe60df9">
                                    Terms & Conditions
                                </a> and
                                <a style="color: #D3FFB0; font-weight: 700;" target="_blank"
                                    href="https://equil-labs.notion.site/EQLX-Privacy-Policy-0fa4da07a5d64a8db8902742f3bc815e">
                                    Privacy Policy
                                </a>
                            </p>
                        </div>
                    </div>
                    <v-btn @click="connectWithGoogle" rounded="xl" block :disabled="!checkbox" color="secondary"
                        class="mb-3"><v-icon color="#222222" size="small" class="mr-1">mdi-google</v-icon>
                        <h4 style="color: #222222;">Connect
                            with
                            Google</h4>
                    </v-btn>
                    <v-card rounded="xl" color="grey" class="pa-1" disabled>
                        <p class="text-center">Connect with email code (coming soon)</p>
                    </v-card>

                    <!-- <v-text-field disabled variant="solo-filled" density="compact" rounded="xl"
                        label="Connect with email code (coming soon)" class="center-text-field"></v-text-field> -->

                    <!-- <v-btn disabled class="mb-3" color="secondary" size="large" rounded="xl" elevation="10" block
                                to="/dashboard"><strong style="color: #222222;">Connect Wallet</strong></v-btn> -->
                </div>
            </v-card>
        </v-container>
    </div>
</template>

<style lang="scss">
.hero {
    background-color: #FBFAF8;
    background: url("@/assets/eqlx_landing.jpg");
    background-size: cover;
    display: flex;
    background-position: top;
}

.heading {
    font-size: 80px;
    line-height: 80px;
    font-weight: 900;
}

@media (max-width: 768px) {
    .hero {
        background-color: #FBFAF8;
        background: url("@/assets/eqlx_landing.jpg");
        background-size: cover;
        display: flex;
        background-position: center;
    }

    .heading {
        font-size: 60px;
        line-height: 60px;
        font-weight: 900;
    }
}

.center-text-field .v-label {
    transform: translateY(-50%);
    top: 50%;
    left: 12%;
    position: absolute;
    pointer-events: none;
    transition: transform 0.2s 0s;
}

/* Add the custom style for the checkbox */
.white-checkbox::v-deep .v-input--selection-control {
    background-color: white;
}
</style>
