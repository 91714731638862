import { createRouter, createWebHistory } from "vue-router";

// Create the router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/full/FullLayoutHome.vue"),
      children: [
        {
          name: "userDashboard",
          path: "/",
          component: () => import("@/views/dashboard/userDashboard.vue"),
          meta: { title: "EQLX | Own your journey" },
        },
        {
          name: "Homepage",
          path: "home",
          component: () => import("@/views/home/home.vue"),
        },
        {
          name: "Feed",
          path: "feed",
          component: () => import("@/views/feed/feed.vue"),
        },
        {
          name: "Collectables Feed",
          path: "collectables",
          component: () => import("@/views/feed/collectables.vue"),
        },
        {
          name: "Following Feed",
          path: "following",
          component: () => import("@/views/feed/followingFeed.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/layouts/BlankLayout.vue"),
      children: [
        {
          name: "Authentication",
          path: "/connect",
          component: () => import("@/views/AuthSignIn.vue"),
          meta: { title: "Connect" },
        },
        {
          name: "Register",
          path: "/register",
          component: () => import("@/views/signIn/accountSetUp.vue"),
          meta: { title: "Register" },
        },
        {
          name: "Create",
          path: "/create",
          component: () => import("@/views/digitalCollectables/create.vue"),
          meta: { title: "Create" },
        },
        {
          name: "MintedMoment",
          path: "/create/minted-moment",
          component: () =>
            import("@/views/digitalCollectables/mintedMoment.vue"),
          meta: { title: "Create Minted Moment" },
        },
        {
          name: "MintStat",
          path: "/create/mint-stat",
          component: () => import("@/views/digitalCollectables/mintStat.vue"),
          meta: { title: "Create Mint" },
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/layouts/full/TransactionLayout.vue"),
      children: [
        {
          name: "SendXRP",
          path: "/send-XRP",
          component: () => import("@/views/dashboard/sendXRP.vue"),
          meta: { title: "Send XRP" },
        },
        {
          name: "buyEQL",
          path: "/buy-eql",
          component: () => import("@/views/dashboard/buyEQL.vue"),
          meta: { title: "Buy EQL" },
        },
        {
          name: "ActivateWallet",
          path: "/activate-wallet",
          component: () => import("@/views/dashboard/ActivateWallet.vue"),
          meta: { title: "Activate Wallet" },
        },

        // ORGANISATION PAGES
        {
          name: "ActivateOrg",
          path: "/activate-org",
          component: () => import("@/views/org/activation.vue"),
          meta: { title: "Activate Organisation" },
        },
        {
          name: "ResultsOrg",
          path: "/org/results",
          component: () => import("@/views/org/results.vue"),
          meta: { title: "Mint Results" },
        },
        {
          name: "MedalsOrg",
          path: "/org/medals",
          component: () => import("@/views/org/medals.vue"),
          meta: { title: "Digital Medals" },
        },
        {
          name: "CreateEvent",
          path: "/org/events/create",
          component: () => import("@/views/org/createEvent.vue"),
          meta: { title: "Create Event" },
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/layouts/full/MintLayout.vue"),
      children: [
        {
          name: "Mint",
          path: "/mint",
          component: () => import("@/views/apps/nft/MINT.vue"),
        },
        {
          name: "Mint Data",
          path: "/mint-data",
          component: () => import("@/views/apps/nft/MINTdata.vue"),
        },
      ],
    },

    {
      path: "/",
      component: () => import("@/layouts/full/MintLayout.vue"),
      children: [
        {
          name: "Create Post",
          path: "post",
          component: () => import("@/views/post/createPost.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/layouts/full/FullLayout.vue"),
      children: [
        {
          name: "Dashboard",
          path: "dashboard",
          component: () => import("@/views/dashboard/userDashboard.vue"),
        },
        {
          name: "Transactions",
          path: "Transactions",
          component: () => import("@/views/transactions/transactions.vue"),
        },
        {
          name: "Organisation",
          path: "organisation",
          component: () => import("@/views/org/orgDashboard.vue"),
        },
        {
          name: "Marketplace",
          path: "marketplace",
          component: () => import("@/views/marketplace/marketplace.vue"),
        },
        {
          name: "My Profile",
          path: "profile/:account",
          component: () => import("@/views/myProfile/myProfile.vue"),
        },
        {
          name: "My Profile redirect",
          path: "profileredirect/:account",
          component: () => import("@/views/myProfile/myProfileRedirect.vue"),
        },
        {
          name: "Advertising",
          path: "advertise",
          component: () => import("@/views/advertising/advertise.vue"),
        },
        {
          name: "Wearables",
          path: "wearables",
          component: () => import("@/views/wearables/wearables.vue"),
        },
        {
          name: "Subscription",
          path: "subscription",
          component: () => import("@/views/subscription/subscription.vue"),
        },
        {
          name: "Tokenomics",
          path: "tokenomics",
          component: () => import("@/views/tokenomics/tokenomics.vue"),
        },
        {
          name: "Notifications",
          path: "notifications",
          component: () => import("@/views/apps/nft/notifications.vue"),
        },
        {
          name: "My NFTs",
          path: "apps/nft",
          component: () => import("@/views/apps/nft/nfts.vue"),
        },
        {
          name: "Explore All NFTs",
          path: "apps/nft/explore",
          component: () => import("@/views/apps/nft/explore.vue"),
        },
        {
          name: "Explore People",
          path: "/people",
          component: () => import("@/views/apps/nft/people.vue"),
        },
        {
          name: "View NFT",
          path: "nft",
          component: () => import("@/views/apps/nft/viewNFT.vue"),
        },
        {
          name: "View Post",
          path: "post/:postID",
          component: () => import("@/views/apps/nft/viewPost.vue"),
        },
      ],
    },
    // {
    //   path: "/",
    //   name: "home",
    //   component: HomePage,
    // },
    // ... other routes as needed
  ],
});

export default router;
