import type AccountRoot from "xrpl/dist/npm/models/ledger/AccountRoot";
import {
  loggedIn,
  provider,
  userInfo,
  web3Account,
  web3auth,
  web3Balance,
  web3EvmAddress,
} from "./web3refs";
import RPC from "./xrplRPC";
import { getMyNFTData, getWalletData } from "@/views/apps/nft/appRefs";

import {
  CHAIN_NAMESPACES,
  CustomChainConfig,
  OPENLOGIN_NETWORK_TYPE,
  WALLET_ADAPTERS,
  WEB3AUTH_NETWORK,
} from "@web3auth/base";
import { saveUserInformation } from "@/firebase/functions";
import { userCredential } from "@/firebase/firebaseRefs";
import { testNet } from "@/xrplservice/storage";
import { User } from "firebase/auth";

import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import Web3 from "web3";

export async function web3EthStartup(user: User): Promise<string | null> {
  console.log("ETH startup");

  let web3AuthNetwork: OPENLOGIN_NETWORK_TYPE = "sapphire_mainnet";
  let verifierId: string = "w3a-firebase-eqlx-mainnet";

  let clientId =
    "BGavMhep2dABSgEf48wqSp1RfLvt33TzXfNDFX5MO3_jXyzWhWq_XvFUMDUTKlTL9fStqvFi1B6kLao7szxbQbs";
  if (testNet.value) {
    web3AuthNetwork = "sapphire_devnet";
    verifierId = "w3a-firebase-eqlx-devnet";
    clientId =
      "BOF14m1UOYqX_Zz6ObJo3IaBdnXpovedHeYPwf_jCysl532h11tYxY2YsYFMF0Ebjc1Y0oPwAuJbReCIpRlt6Jc";
  }

  const chainConfig: CustomChainConfig = {
    chainNamespace: "eip155",
    chainId: "0x89", // hex of 137, polygon mainnet
    rpcTarget: "https://rpc.ankr.com/polygon",
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: "Polygon Mainnet",
    // @ts-ignore
    blockExplorerUrl: "https://polygonscan.com",
    ticker: "MATIC",
    tickerName: "MATIC",
    logo: "https://cryptologos.cc/logos/polygon-matic-logo.png",
  };
  const privateKeyProvider = new EthereumPrivateKeyProvider({
    config: { chainConfig },
  });
  const web3auth = new Web3AuthNoModal({
    clientId: clientId, // Get your Client ID from the Web3Auth Dashboard
    chainConfig,
    web3AuthNetwork: web3AuthNetwork,
    // @ts-ignore
    privateKeyProvider,
    uiConfig: {
      theme: {
        primaryColor: "#FF0000",
        secondaryColor: "#00FF00",
        textColor: "#0000FF",
      },
    },
  });

  const adapter = new OpenloginAdapter({
    adapterSettings: {
      uxMode: "redirect", // redirect or popup
      whiteLabel: {
        appName: "EQLX",
        appUrl: "https://eqlx.io",
        logoDark:
          "https://equil.io/wp-content/uploads/2023/11/Charcoal-Wordmark.svg",
        defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
        mode: "dark", // whether to enable dark mode. defaultValue: auto
        tncLink: { en: "https://help.eqlx.io/terms-of-use" },
        privacyPolicy: { en: "https://help.eqlx.io/privacy-policy" },
        theme: {
          primary: "#26A9E1",
        },
        useLogoLoader: true,
      },
      loginConfig: {
        jwt: {
          verifier: verifierId, // name of the verifier created on Web3Auth Dashboard
          typeOfLogin: "jwt",
          clientId: clientId,
        },
      },
    },
    // @ts-ignore
    privateKeyProvider: privateKeyProvider,
  });
  web3auth.configureAdapter(adapter);

  await web3auth.init();
  const idToken = await user.getIdToken(true);

  let web3authProvider = web3auth.provider;

  if (!web3auth.connected) {
    web3authProvider = await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
      loginProvider: "jwt",
      extraLoginOptions: {
        id_token: idToken,
        verifierIdField: "email", // same as your JWT Verifier ID field
      },
    });
  }

  if (!web3authProvider) {
    console.error("EVM: web3authProvider not found");
    return null;
  }

  const web3 = new Web3(web3authProvider);
  const address = (await web3.eth.getAccounts())[0];
  web3EvmAddress.value = address;
  console.log("EVM: address", address);

  return address;

  // EVM
}

export async function web3Startup(user: User) {
  const { Web3AuthNoModal } = await import("@web3auth/no-modal");
  const { OpenloginAdapter } = await import("@web3auth/openlogin-adapter");
  const { XrplPrivateKeyProvider, getXRPLChainConfig } = await import(
    "@web3auth/xrpl-provider"
  );

  let web3AuthNetwork: OPENLOGIN_NETWORK_TYPE = "sapphire_mainnet";
  let verifierId: string = "w3a-firebase-eqlx-mainnet";
  let xrplNetwork: string = "mainnet";

  let clientId =
    "BGavMhep2dABSgEf48wqSp1RfLvt33TzXfNDFX5MO3_jXyzWhWq_XvFUMDUTKlTL9fStqvFi1B6kLao7szxbQbs";
  if (testNet.value) {
    web3AuthNetwork = "sapphire_devnet";
    verifierId = "w3a-firebase-eqlx-devnet";
    xrplNetwork = "testnet";
    clientId =
      "BOF14m1UOYqX_Zz6ObJo3IaBdnXpovedHeYPwf_jCysl532h11tYxY2YsYFMF0Ebjc1Y0oPwAuJbReCIpRlt6Jc";
  }

  web3auth.value = new Web3AuthNoModal({
    chainConfig: {
      chainNamespace: "other",
      chainId: "0x04",
      rpcTarget: "https://s.altnet.rippletest.net:51234", // devnet, testnet, mainnet
    },
    clientId: clientId,
    web3AuthNetwork: web3AuthNetwork, // testnet, mainnet, cyan, aqua
    // uiConfig: uiConfig,
    // useCoreKitKey: true,
    storageKey: "local",
  });
  if (web3auth.value.connected) {
    web3auth.value.authenticateUser();
    return;
  }

  const xrplProvider = new XrplPrivateKeyProvider({
    config: {
      chainConfig: getXRPLChainConfig(xrplNetwork), // devnet, testnet, mainnet
    },
  });

  const adapter = new OpenloginAdapter({
    adapterSettings: {
      uxMode: "redirect", // redirect or popup
      whiteLabel: {
        appName: "EQLX",
        appUrl: "https://eqlx.io",
        logoDark:
          "https://equil.io/wp-content/uploads/2023/11/Charcoal-Wordmark.svg",
        defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
        mode: "dark", // whether to enable dark mode. defaultValue: auto
        tncLink: { en: "https://help.eqlx.io/terms-of-use" },
        privacyPolicy: { en: "https://help.eqlx.io/privacy-policy" },
        theme: {
          primary: "#26A9E1",
        },
        useLogoLoader: true,
      },
      loginConfig: {
        jwt: {
          verifier: verifierId, // name of the verifier created on Web3Auth Dashboard
          typeOfLogin: "jwt",
          clientId: clientId,
        },
      },
    },
    privateKeyProvider: xrplProvider,
  });
  web3auth.value.configureAdapter(adapter);
  await web3auth.value.init();

  const idToken = await user.getIdToken(true);

  if (web3auth.value.connected) {
    provider.value = web3auth.value.provider;
  } else if (idToken) {
    const web3authProvider = await web3auth.value.connectTo(
      WALLET_ADAPTERS.OPENLOGIN,
      {
        loginProvider: "jwt",
        extraLoginOptions: {
          id_token: idToken,
          verifierIdField: "email", // same as your JWT Verifier ID field
        },
      }
    );
    provider.value = web3authProvider;
  }

  await getUserInfo();
  await getAccounts();
  // await getBalance();
  loggedIn.value = true;

  const ethAddress = await web3EthStartup(user);

  if (web3Account.value && userCredential.value) {
    await saveUserInformation(
      userCredential.value.user.uid,
      userInfo.value?.email ?? "",
      web3Account.value,
      ethAddress
    );
  }
}

const authenticateUser = async () => {
  if (!web3auth.value) {
    return;
  }
  const idToken = await web3auth.value.authenticateUser();
};

const getUserInfo = async () => {
  if (!web3auth.value) {
    return;
  }
  const user = await web3auth.value.getUserInfo();
  userInfo.value = user;
};

const getAccounts = async () => {
  if (!provider.value) {
    return;
  }
  const rpc = new RPC(provider.value);
  const userAcc: { account: string | undefined } & {
    account_data: AccountRoot | undefined;
  } = await rpc.getAccounts();
  console.log("userAcc", userAcc);
  if (userAcc.account) {
    console.log("account NOT set up yet");
    web3Account.value = userAcc.account;
  }
  if (userAcc.account_data) {
    console.log("account IS set up");
    web3Account.value = userAcc.account_data.Account;
    // getWalletData(web3Account.value);
    // getNotifications(web3Account.value);
    // getMyNFTData(web3Account.value);
  }
  console.log("account", web3Account.value);
};

export const getBalance = async () => {
  if (!provider.value) {
    return;
  }
  const rpc = new RPC(provider.value);
  const bal = await rpc.getBalance();
  console.log("balance", bal);
  if (bal) {
    web3Balance.value = bal / 1000000;
  } else {
    web3Balance.value = 0;
  }
};
