import { ref, type Ref } from "vue";

const appBaseURL = window.location.protocol + "//" + window.location.host;

export const DEFAULT_TESTNET = !appBaseURL.includes("https://eqlx.io");

export function getTestNetSetting(): boolean {
  const testnet = localStorage.getItem("testNet");
  return testnet ? JSON.parse(testnet) : DEFAULT_TESTNET;
}

export const testNet: Ref<boolean> = ref(getTestNetSetting());
export const appMinBlock: Ref<number> = ref(
  testNet.value ? 40580100 : 82051399
);
export const netName: Ref<string> = ref(
  testNet.value ? "Test Net" : "Main Net"
);

export const xrplUrl: Ref<string> = ref(
  testNet.value ? "https://testnet.xrpl.org" : "https://livenet.xrpl.org"
);

export function saveTestNetSetting() {
  localStorage.setItem("testNet", JSON.stringify(testNet.value));
  testNet.value = getTestNetSetting();
  if (testNet.value) {
    netName.value = "Test Net";
    xrplUrl.value = "https://testnet.xrpl.org";
  } else {
    netName.value = "Main Net";
    xrplUrl.value = "https://livenet.xrpl.org";
  }
}

const LAST_BLOCK = "lastBlock";

export function getLastBlock(): number {
  const lastBlock = localStorage.getItem(LAST_BLOCK);
  return lastBlock ? parseInt(lastBlock) : -1;
}

export function saveLastBlock(lastBlock: number) {
  localStorage.setItem(LAST_BLOCK, lastBlock.toString());
}

const KUDOS_NUM = "KudosNum";

export function saveKudosNum(kudosNum: number): void {
  if (!localStorage) {
    return;
  }
  localStorage.setItem(KUDOS_NUM, JSON.stringify(kudosNum));
}

export function getKudosNum(): number {
  if (!localStorage) {
    return 0;
  }
  const kudosNum = localStorage.getItem(KUDOS_NUM);
  if (!kudosNum) {
    return 0;
  }
  return JSON.parse(kudosNum);
}
