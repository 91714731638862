<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { RouterView } from "vue-router";
import { User, onAuthStateChanged, getRedirectResult } from "firebase/auth";
import { auth } from "./firebase/functions";
import { web3EthStartup, web3Startup } from "./web3auth/startup";

import AuthSignIn from "./views/AuthSignIn.vue";
import { userCredential } from "./firebase/firebaseRefs";

import LogoLight from "@/layouts/full/logo/LogoLight.vue"

const enteredPassword = ref('');
const password = ref('champion');
const showButton = ref(false);

watch(enteredPassword, (newValue: string) => {
  showButton.value = newValue === password.value;
});

const viewed = ref(false);
const userRef = ref<User | null>(null);
const loading = ref(true);

onMounted(() => {
  const viewedString = localStorage.getItem("landingViewed");
  if (viewedString) {
    viewed.value = JSON.parse(viewedString);
  }
  localStorage.setItem("landingViewed", JSON.stringify(true));
});

onAuthStateChanged(auth, (user) => {
  console.log("Auth state changed. User:", user);
  if (user) {
    userCredential.value = {
      user: user,
      operationType: "signIn",
      providerId: null,
    }



    web3Startup(user).then(() => {
      console.log("Web3 startup completed");
      userRef.value = user;
      loading.value = false;
    }).catch((error) => {
      console.error("Error in web3 startup:", error);
    });




  } else {
    console.log("User logged out or not signed in");
    loading.value = false;
  }
});

</script>

<template>
  <v-app v-if="!viewed">
    <div class="hero" style="height: 100vh;">
      <v-container style="height: 100vh;">
        <v-row class="d-flex pa-5">
          <v-col cols="12" lg="12" class="d-flex justify-space-between">
            <LogoLight style="width: 180px;"></LogoLight>
            <v-img style="max-width: 100px;" class="align-self-center" src="@/assets/xrpledger-white.svg"></v-img>
          </v-col>
          <v-col cols="12" lg="6" class="">
            <h1 class="heading mobileCenter"
              style="color: white; padding-top: 75px; font-size: 60px; line-height: 1.0; font-weight: 900;">
              OWN
              YOUR
              <br>JOURNEY
            </h1>
            <h3 class="mb-5 mt-5 mobileCenter"
              style="color: white; font-weight: 300; font-size: 32px; line-height: 1.0;">
              EQLX is a social plaform for athletes and sports fans.
            </h3>
            <h5 class="mb-10 mt-5 mobileCenter"
              style="color: white; font-weight: 300; font-size: 18px; line-height: 1.0;">You can
              <strong style="font-weight: 700; color: #D3FFB0;">own</strong> your sports data and
              be rewarded using Blockchain technology.
            </h5>
            <v-text-field rounded="xl" v-if="!showButton" type="password" label="enter beta access code" variant="solo"
              v-model="enteredPassword"></v-text-field>
            <div class="mobileCenter">
              <v-btn block v-if="showButton" class="ml-3 mt-3" color="#D3FFB0" size="x-large" rounded="xl"
                elevation="10" @click="viewed = !viewed"><strong style="color: black; font-family: Outfit;">Connect
                  Wallet</strong></v-btn>
              <!-- <v-btn v-if="showButton" color="#D3FFB0" variant="outlined" rounded="xl" size="x-large" class="ml-3 mt-3"
                to="/feed"><strong style="color: #D3FFB0; font-family: Outfit;">Explore
                  EQLX</strong></v-btn><br> -->
              <v-btn class="ml-3 mt-10" color="white" prepend-icon="mdi-arrow-down" variant="text" size="large"
                href="#features"><strong style="font-family: Outfit;">What you can do with
                  EQLX</strong></v-btn>
            </div>
          </v-col>
          <!-- <v-col class="hidden-sm-and-down" cols="12" lg="6"> -->
          <v-col cols="12" lg="6">
            <div class="d-flex align-center" style="height: 100%;">
              <v-img src="@/assets/polaroid_features.png"></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="down" id="features">
      <v-container style="padding-bottom: 200px; padding-top: 200px;">
        <v-row class="pa-5">
          <v-col cols="12" lg="12" sm="12" class="mt-10 mb-10">
            <div style="color: white;">
              <h1 class="heading">Connect with your sporting community
                & own your sports data on the blockchain.</h1>
            </div>
          </v-col>

          <v-col cols="12" lg="12" sm="12" class="mt-10 mb-10">
            <v-row>
              <v-col cols="12" lg="4" sm="12" class="d-flex jusitfy-center">
                <v-img width="300px" src="@/assets/profile.png"></v-img>
              </v-col>
              <v-col cols="12" lg="8" sm="12" class="align-self-center text-white">
                <h2 style="font-size: 44px; line-height: 1.0; margin-bottom: 20px;">Own your Digital Athlete
                  Profile</h2>
                <p>Create a profile and connect with people in your sporting community. Share your progress,
                  achievements, stats and encourage others. All your data is linked to your XRP Ledger
                  digital
                  wallet address.</p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" lg="12" sm="12" class="mt-10 mb-10">
            <v-row>
              <v-col cols="12" lg="4" sm="12" class="d-flex jusitfy-center">
                <v-img width="300px" src="@/assets/digitalSignature.png"></v-img>
              </v-col>
              <v-col cols="12" lg="8" sm="12" class="align-self-center text-white">
                <h2 style="font-size: 44px; line-height: 1.0; margin-bottom: 20px;">Collect Digital
                  Signatures
                </h2>
                <p>Mint digital collectibles and collect digital signatures from your favourite athletes and
                  teams.</p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" lg="12" sm="12" class="mt-10 mb-10">
            <v-row>
              <v-col cols="12" lg="4" sm="12" class="d-flex jusitfy-center">
                <v-img width="300px" src="@/assets/collectables.png"></v-img>
              </v-col>
              <v-col cols="12" lg="8" sm="12" class="align-self-center text-white">
                <h2 style="font-size: 44px; line-height: 1.0; margin-bottom: 20px;">Mint Digital
                  Collectables
                </h2>
                <p>Turn your sporting moments into Digital collectibles that can be traded with XRP.</p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" lg="12" sm="12" class="mt-10 mb-10">
            <v-row>
              <v-col cols="12" lg="4" sm="12" class="d-flex jusitfy-center">
                <v-img width="300px" src="@/assets/EQL.png"></v-img>
              </v-col>
              <v-col cols="12" lg="8" sm="12" class="align-self-center text-white">
                <h2 style="font-size: 44px; line-height: 1.0; margin-bottom: 20px;">Earn EQL Points</h2>
                <p>Earn EQL Points for engagement and spend EQL on comments as digital signature on your
                  other
                  accounts.</p>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </v-container>
    </div>

    <v-footer class="footerMobile" app fixed
      style="background: linear-gradient(to bottom, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.8) 50%)">
      <v-container>
        <div class="d-flex justify-space-between footer">
          <div class="d-flex">
            <p class="footer" style="font-family: Outfit;">© <strong>EQLX</strong> all rights reserved 2024</p>
            <p style="margin-left: 20px;"><a target="_blank" href="https://equil.io"
                style="color: white; font-family: Outfit; color: #71797E;">A project by EQUIL LABS PTY
                LTD</a></p>
          </div>
          <div class="text-right d-flex">
            <p class="footer" style="margin-right: 20px;"><a target="_blank"
                href="https://equil-labs.notion.site/Terms-of-Use-Privacy-Policy-6005ccadca5f427aaf9af0e0dfe60df9?pvs=4"
                style="color: white; font-family: Outfit;">Terms of Use & Privacy Policy</a></p>
            <p class="footer"><a target="_blank"
                href="https://equil-labs.notion.site/Help-Support-1718d4f9c3db42dbbcd0b096273986da?pvs=4"
                style="color: white; font-family: Outfit;">Support</a>
            </p>
          </div>
        </div>
      </v-container>
    </v-footer>

    <v-footer class="footerDesktop" style="background: #141414">
      <v-container>
        <div class="d-flex justify-space-between footer">
          <div>
            <p class="footer" style="font-family: Outfit;">© <strong>EQLX</strong> all rights reserved 2024</p>
            <p><a target="_blank" href="https://equil.io" style="color: white; font-family: Outfit; color: #71797E;">A
                project by EQUIL LABS PTY
                LTD</a></p>
          </div>
          <div class="text-right">
            <p class="footer"><a target="_blank"
                href="https://equil-labs.notion.site/Terms-of-Use-Privacy-Policy-6005ccadca5f427aaf9af0e0dfe60df9?pvs=4"
                style="color: white; font-family: Outfit;">Terms of Use & Privacy Policy</a></p>
            <p class="footer"><a target="_blank"
                href="https://equil-labs.notion.site/Help-Support-1718d4f9c3db42dbbcd0b096273986da?pvs=4"
                style="color: white; font-family: Outfit;">Support</a>
            </p>
          </div>
        </div>
      </v-container>
    </v-footer>
  </v-app>

  <!-- <div v-else-if="loading">
    <v-col cols="12" lg="12" sm="12" align="center" style="height: 100vh;">
      <div class="align-self-center text-center" style="padding-top: 100px;">
        <h1>Loading your EQLX wallet</h1>
        <p>Please wait and keep this window open.</p>
        <br>
        <v-progress-circular indeterminate color="secondary"></v-progress-circular>
      </div>
    </v-col>
  </div> -->

  <v-dialog v-model="loading" v-else-if="loading" style="height: 100vh; background-color: rgba(34, 34, 34, 0.8);">
    <div class="align-self-center text-center">
      <div class="d-flex justify-center mb-10">
        <div style="width: 200px;"><v-img src="@/assets/white-logo-text.svg"></v-img></div>
      </div>
      <h1 style="color: white;">Loading Wallet</h1>
      <p style="color: white;">Keep this window open and allow pop-ups</p>
      <br>
      <v-progress-circular indeterminate color="secondary" size="large"></v-progress-circular>
    </div>
  </v-dialog>

  <div v-else-if="userRef">
    <RouterView />
  </div>

  <div v-else>
    <AuthSignIn></AuthSignIn>
  </div>
</template>

<style lang="scss">
.footer {
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  color: #FBFAF8;
}

.hero {
  background-size: cover;
  display: flex;
  background-position: top;
  height: 100vh;
  background: linear-gradient(rgba(20, 20, 20, 0.1), rgba(20, 20, 20, 1)), url('@/assets/eqlx_landing.jpg');
}

.down {
  background-color: #141414;
  background-size: cover;
  display: flex;
  background-position: top;
  padding-top: 0px;
}

.heading {
  font-size: 80px;
  line-height: 80px;
  font-weight: 900;
}

@media (max-width: 768px) {
  .hero {
    background: linear-gradient(rgba(20, 20, 20, 0.1), rgba(20, 20, 20, 1)), url('@/assets/eqlx_landing.jpg');
    background-size: cover;
    display: flex;
    background-position: center;
  }

  .down {
    text-align: center;
    padding-top: 150px;
  }

  .heading {
    font-size: 60px;
    line-height: 60px;
    font-weight: 900;
  }

  .footerMobile {
    display: none;
    /* Hide the item on mobile */
  }

  .mobileCenter {
    text-align: center;
  }
}

@media (min-width: 769px) {
  .footerDesktop {
    display: none;
    /* Hide the item on desktop */
  }
}
</style>
