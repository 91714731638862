import type { IProvider } from "@web3auth/base";
import type { Web3AuthNoModal } from "@web3auth/no-modal";
import type { OpenloginUserInfo } from "@web3auth/openlogin-adapter/dist/types/interface";
import { ref, type Ref } from "vue";

export const web3auth: Ref<Web3AuthNoModal | null> = ref(null);
export const provider: Ref<IProvider | null> = ref(null);
export const loggedIn: Ref<boolean | null> = ref(false);
export const userInfo: Ref<Partial<OpenloginUserInfo> | null> = ref(null);
export const web3Account: Ref<string | null> = ref(null);
export const web3Balance: Ref<number | null> = ref(null);
export const web3EvmAddress: Ref<string | null> = ref(null);
