import { COLD_ADDRESS } from "@/xrplservice/consts";
import { getEquilBal } from "@/xrplservice/filters/equilBalance";
import { testNet } from "@/xrplservice/storage";
import {
  convertStringToHex,
  getClient,
  xrpToDrops,
} from "@/xrplservice/xrplService";
import type { IProvider, Maybe } from "@web3auth/base";
import type { NFTokenCreateOffer, Payment, Transaction } from "xrpl";
import type AccountRoot from "xrpl/dist/npm/models/ledger/AccountRoot";
import type { BuyOfferData, sendTokenData } from "@/xrplservice/models";
import { appInfo } from "@/views/apps/nft/appRefs";
import { web3Account } from "./web3refs";

export default class XrplRPC {
  private provider: IProvider;

  constructor(provider: IProvider) {
    this.provider = provider;
  }

  getAccounts = async (): Promise<any> => {
    try {
      const accounts = await this.provider.request<never, string[]>({
        method: "xrpl_getAccounts",
      });
      if (accounts) {
        const accInfo = await this.provider.request({
          method: "account_info",
          params: [
            {
              account: accounts[0],
              strict: true,
              ledger_index: "current",
              queue: true,
            },
          ],
        });
        return accInfo;
      } else {
        return "No accounts found, please report this issue.";
      }
    } catch (error) {
      console.error("Error", error);
      return error;
    }
  };

  signMessage = async (msg: string): Promise<any> => {
    try {
      const txSign = await this.provider.request({
        method: "xrpl_signMessage",
        params: {
          message: convertStringToHex(msg),
        },
      });
      return txSign;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  signAndSendTransaction = async (
    tx: Transaction
  ): Promise<{
    success: boolean;
    txHash: string | undefined;
    nftID: string | undefined;
  }> => {
    try {
      const accInfo = await this.getAccountInfo();
      const txJson = {
        ...tx,
        Sequence: accInfo.account_data.Sequence,
        Fee: "12",
        // @ts-ignore
        LastLedgerSequence: accInfo.ledger_current_index + 10,
      };

      const txSign = (await this.provider.request({
        method: "xrpl_signTransaction",
        params: {
          transaction: txJson,
        },
      })) as { tx_blob: string; hash: string };
      const res = await submitAndValidate(txSign.tx_blob);

      let txHash: string | undefined = undefined;
      let nftID: string | undefined = undefined;
      try {
        txHash = res.result.hash;
      } catch (error) {
        console.log("error", error);
      }

      try {
        nftID = res.result.meta.nftoken_id;
      } catch (error) {
        console.log("error", error);
      }

      const success = res.result.meta.TransactionResult == "tesSUCCESS";

      return { success, txHash, nftID };
    } catch (error) {
      console.log("error", error);
      return { success: false, txHash: undefined, nftID: undefined };
    }
  };

  signTransaction = async (
    tx: Transaction
  ): Promise<{ tx_blob: string; hash: string } | undefined> => {
    try {
      const accInfo = await this.getAccountInfo();

      if (!accInfo || !accInfo.account_data) {
        return;
      }

      const txJson = {
        ...tx,
        Sequence: accInfo.account_data.Sequence,
        Fee: "12",
        // @ts-ignore
        LastLedgerSequence: accInfo.ledger_current_index + 10,
      };

      const txSign = await this.provider.request({
        method: "xrpl_signTransaction",
        params: {
          transaction: txJson,
        },
      });
      return txSign as { tx_blob: string; hash: string };
    } catch (error) {
      console.log("Error message:", error);
      console.log("Error details:", JSON.stringify(error, null, 2));
      throw error;
      return;
    }
  };

  getAccountInfo = async (): Promise<{
    account_data: AccountRoot;
  }> => {
    const accounts = await this.provider.request<never, string[]>({
      method: "xrpl_getAccounts",
    });

    if (accounts && accounts.length > 0) {
      const acc = accounts[0];

      if (!acc) {
        throw new Error("failed to fetch account info");
      }

      // ! WARNING accInfo might be:
      // { account: string | undefined } & {
      //   account_data: AccountRoot | undefined;
      // }

      const accInfo: Maybe<{ account_data: AccountRoot }> =
        await this.provider.request({
          method: "account_info",
          params: [
            {
              account: accounts[0],
              strict: true,
              ledger_index: "current",
              queue: true,
            },
          ],
        });
      // But this check should cover it
      if (accInfo && accInfo.account_data) {
        return accInfo as { account_data: AccountRoot };
      }
    }
    throw new Error("failed to fetch account info");
  };

  getBalance = async (): Promise<any> => {
    try {
      const accInfo = await this.getAccountInfo();
      return accInfo.account_data.Balance;
    } catch (error) {
      console.log("Error", error);
      return null;
    }
  };
}

async function submitAndValidate(txSign: any) {
  const client = getClient(testNet.value);
  await client.connect();
  const response = await client.submitAndWait(txSign);
  client.disconnect();
  return response;
}
