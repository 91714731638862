import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  signInWithCredential,
  signInWithEmailAndPassword,
  UserCredential,
  setPersistence,
  browserLocalPersistence,
  AuthCredential,
  User,
} from "firebase/auth";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export const FIREBASE_API_KEY = process.env.VUE_APP_FIREBASE_API_KEY ?? "";

let authDomain = "eqlx-staging.web.app";

if (!testNet.value) {
  authDomain = "eqlx.io";
}

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: authDomain,
  projectId: "equil-79624",
  storageBucket: "equil-79624.appspot.com",
  messagingSenderId: "1028733181489",
  appId: "1:1028733181489:web:d2dfeb15c00fae3be3ff10",
  measurementId: "G-SB2Y1G3584",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

let db = getFirestore(app);
let storage = getStorage(app);

if (!testNet.value) {
  db = getFirestore("mainnet");
  storage = getStorage(app, "gs://equil-79624");
}

import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  getDocs,
  collection,
  arrayUnion,
  arrayRemove,
  DocumentData,
  query,
  DocumentReference,
  deleteDoc,
} from "firebase/firestore";
import { userCredential } from "@/firebase/firebaseRefs";
import {
  Comment,
  Notification,
  Post,
  PubUserComment,
  PublicInfo,
} from "./models";
import CryptoJS from "crypto-js";

export const signInWithEmail = async (
  email: string,
  password: string
): Promise<UserCredential> => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const signInWithGoogle = async (): Promise<UserCredential> => {
  try {
    const googleProvider = new GoogleAuthProvider();
    await setPersistence(auth, browserLocalPersistence);

    // get idToken from local storage

    const idToken = localStorage.getItem("idToken");

    if (!idToken) {
      const res = await signInWithRedirect(auth, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(res);
      const idToken = credential?.idToken;
      // save idToken to local storage
      if (idToken) {
        localStorage.setItem("idToken", idToken);
      }
      return res;
    }
    try {
      const creds: AuthCredential = GoogleAuthProvider.credential(idToken);
      const red = await signInWithCredential(auth, creds);
      return red;
    } catch (err) {
      console.error(err);
      localStorage.setItem("idToken", "");
      const res = await signInWithRedirect(auth, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(res);
      const idToken = credential?.idToken;
      // save idToken to local storage
      if (idToken) {
        localStorage.setItem("idToken", idToken);
      }
      return res;
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const saveUserInformation = async (
  idToken: string,
  email: string,
  address: string,
  ethAddress: string | null = null
) => {
  try {
    const userDocRef = doc(db, "users", idToken);
    const docSnap = await getDoc(userDocRef);

    // Check if the document exists
    if (!docSnap.exists()) {
      // Document does not exist, create a new one
      await setDoc(userDocRef, {
        email: email,
        address: address,
        ethAddress: ethAddress,
      });
      console.log("User information saved successfully.");
    } else {
      console.log("Updating uer address");
      await updateDoc(userDocRef, {
        email: email,
        address: address,
        ethAddress: ethAddress,
      });
    }
  } catch (err) {
    console.error("Error saving user information: ", err);
  }
};

export async function signOut() {
  const auth = getAuth(app);
  await auth.signOut();
}

export const updateProfile = async (
  uid: string,
  address: string,
  publicInfo: PublicInfo
): Promise<boolean> => {
  try {
    // Reference to the user's private document
    const privateDocRef = doc(db, "users", uid);
    const privateDocSnap = await getDoc(privateDocRef);

    if (!privateDocSnap.exists() || privateDocSnap.data().address !== address) {
      console.log("No matching user found or address mismatch.");
      return false;
    }

    // Reference to the user's public profile document

    const publicProfileDocRef: DocumentReference = doc(
      db,
      "publicUsers",
      address
    );
    const publicDocSnap = await getDoc(publicProfileDocRef);
    if (!publicDocSnap.exists()) {
      await setDoc(publicProfileDocRef, {
        ...publicInfo,
      });
    } else {
      await updateDoc(publicProfileDocRef, {
        ...publicInfo,
      });
    }

    console.log("Public profile updated successfully.");
    return true;
  } catch (err) {
    console.error("Error updating public profile: ", err);
    return false;
  }
};

export const deactivateAccount = async (
  uid: string,
  address: string,
  deactivated: boolean = true
): Promise<boolean> => {
  try {
    // Reference to the user's private document
    const privateDocRef = doc(db, "users", uid);
    const privateDocSnap = await getDoc(privateDocRef);

    if (!privateDocSnap.exists() || privateDocSnap.data().address !== address) {
      console.log("No matching user found or address mismatch.");
      return false;
    }

    // Reference to the user's public profile document

    const publicProfileDocRef: DocumentReference = doc(
      db,
      "publicUsers",
      address
    );

    const publicInfo = {
      deactivated: deactivated,
    };

    // Update public profile
    await updateDoc(publicProfileDocRef, {
      ...publicInfo,
    });
    console.log("Account Deactivated successfully.");
    return true;
  } catch (err) {
    console.error("Error updating public profile: ", err);
    return false;
  }
};

export const getAllPublicUsers = async (): Promise<
  { pubInfo: PublicInfo; id: string }[]
> => {
  try {
    // Reference to the 'publicUsers' collection
    const publicUsersColRef = collection(db, "publicUsers");

    // Get all documents from the 'publicUsers' collection
    const querySnapshot = await getDocs(publicUsersColRef);

    // Map each document into a PublicUser object
    const publicUsers = querySnapshot.docs.map((doc) => ({
      pubInfo: doc.data(),
      id: doc.id,
    })) as any as { pubInfo: PublicInfo; id: string }[];

    return publicUsers;
  } catch (error) {
    console.error("Error fetching public users:", error);
    throw new Error("Failed to fetch public users");
  }
};

export const getProfile = async (
  address: string
): Promise<{ pubInfo: PublicInfo; id: string } | undefined> => {
  try {
    // Reference to the user's private document
    const privateDocRef = doc(db, "publicUsers", address);
    const privateDocSnap = await getDoc(privateDocRef);

    const id = privateDocSnap.id;
    const pubInfo = privateDocSnap.data() as PublicInfo;
    return { pubInfo, id };
  } catch (err) {
    console.error("Error updating public profile: ", err);
    return;
  }
};

import {
  getDownloadURL,
  getStorage,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import imageCompression from "browser-image-compression";

export const uploadImage = async (
  images: File[],
  imageName: string | null,
  where: string = "profileImages"
): Promise<string> => {
  try {
    // Assuming the first file in the array is the one to be processed
    const imageFile = images[0];

    // Options for the image compression
    const options = {
      maxSizeMB: 1, // (Max file size in MB)
      maxWidthOrHeight: 1000, // (Compressed file's maximum dimension in pixels)
      useWebWorker: true,
      fileType: "image/jpeg", // Convert to JPEG
    };

    // Reduce the size of the image and convert to JPEG
    const compressedImage = await imageCompression(imageFile, options);
    const imageBlob1 = await imageCompression.getDataUrlFromFile(
      compressedImage
    );

    if (!imageName) {
      // If no image name is given, generate a random one
      imageName = CryptoJS.MD5(imageBlob1).toString();
    }

    // Create a reference to the file location in Firebase Storage
    const imageRef = storageRef(storage, `${where}/${imageName}.jpg`);

    // Convert compressed image file to 'Blob' since 'uploadBytes' doesn't accept 'File'
    const imageBlob = await imageCompression.getDataUrlFromFile(
      compressedImage
    );
    const response = await fetch(imageBlob);
    const blob = await response.blob();

    const uploadResult = await uploadBytes(imageRef, blob);

    // Get the URL of the uploaded image
    const imageUrl = await getDownloadURL(uploadResult.ref);

    console.log("Image uploaded successfully!", imageUrl);

    // Return the URL of the uploaded image
    return imageUrl;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const uploadFile = async (
  file: File,
  where: string
): Promise<string> => {
  try {
    // Create a reference to the file location in Firebase Storage
    const fileRef = storageRef(storage, `${where}/${file.name}`);

    const uploadResult = await uploadBytes(fileRef, file);

    // Get the URL of the uploaded image
    const fileUrl = await getDownloadURL(uploadResult.ref);

    console.log("File uploaded successfully!", fileUrl);

    // Return the URL of the uploaded image
    return fileUrl;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const follow = async (
  account: string,
  toFollow: string
): Promise<void> => {
  try {
    // Reference to the 'account' document in the 'following' collection
    const accountDocRef = doc(db, "following", account);

    // Check if the document exists
    const docSnap = await getDoc(accountDocRef);

    if (docSnap.exists()) {
      // If the document exists, update the 'following' array
      await setDoc(
        accountDocRef,
        {
          following: arrayUnion(toFollow),
        },
        { merge: true }
      );
    } else {
      // If the document does not exist, create it with the 'toFollow' user in the 'following' array
      await setDoc(accountDocRef, {
        following: [toFollow],
      });
    }

    console.log(`Account ${account} is now following ${toFollow}`);
  } catch (error) {
    console.error("Error following account:", error);
    throw new Error("Failed to follow account");
  }
};

export const isFollowing = async (
  account: string,
  followAccount: string
): Promise<boolean> => {
  try {
    // Reference to the 'account' document in the 'following' collection
    const accountDocRef = doc(db, "following", account);

    // Retrieve the document for 'account'
    const accountDoc = await getDoc(accountDocRef);

    if (accountDoc.exists()) {
      // Check if 'followAccount' is in the 'following' array
      const followingList = accountDoc.data().following;
      return followingList.includes(followAccount);
    } else {
      // Document does not exist, which means 'account' is not following anyone
      return false;
    }
  } catch (error) {
    console.error("Error checking following status:", error);
    throw new Error("Failed to check if following");
  }
};

export const unfollow = async (
  account: string,
  toUnfollow: string
): Promise<void> => {
  try {
    // Reference to the 'account' document in the 'following' collection
    const accountDocRef = doc(db, "following", account);

    // Remove 'toUnfollow' from the 'following' array for this account
    await updateDoc(accountDocRef, {
      following: arrayRemove(toUnfollow),
    });

    console.log(`Account ${account} has unfollowed ${toUnfollow}`);
  } catch (error) {
    console.error("Error unfollowing account:", error);
    throw new Error("Failed to unfollow account");
  }
};

export const getFollowing = async (account: string): Promise<string[]> => {
  try {
    // Reference to the 'account' document in the 'following' collection
    const accountDocRef = doc(db, "following", account);

    // Retrieve the document for 'account'
    const accountDoc = await getDoc(accountDocRef);

    if (accountDoc.exists()) {
      // Return the 'following' array
      return accountDoc.data().following;
    } else {
      // Document does not exist, which means 'account' is not following anyone
      return [];
    }
  } catch (error) {
    console.error("Error getting following list:", error);
    throw new Error("Failed to get following list");
  }
};

export const getFollowers = async (account: string): Promise<string[]> => {
  try {
    // Reference to the 'account' document in the 'following' collection
    const accountDocRef = doc(db, "followers", account);

    // Retrieve the document for 'account'
    const accountDoc = await getDoc(accountDocRef);

    if (accountDoc.exists()) {
      // Return the 'following' array
      return accountDoc.data().followers;
    } else {
      // Document does not exist, which means 'account' is not following anyone
      return [];
    }
  } catch (error) {
    console.error("Error getting following list:", error);
    throw new Error("Failed to get following list");
  }
};

export async function createPost(
  postData: Post,
  userId: string
): Promise<string> {
  // Hash the postData object
  const dataString = JSON.stringify(postData);
  const dataHash = CryptoJS.MD5(dataString).toString();

  // Reference to the specific document using the dataHash
  const postDocRef = doc(db, "posts", dataHash);

  try {
    // Check if a post with the same hash already exists to avoid duplicates
    const postDoc = await getDoc(postDocRef);
    if (postDoc.exists()) {
      console.log("Post already exists");
      return postDocRef.id;
    }

    // Create a new post document with the dataHash as the key
    await setDoc(postDocRef, postData);
    console.log("Post added successfully");

    // Now add this post's DocumentReference to the publicUsers document's posts field
    const publicUserDocRef = doc(db, "publicUsers", userId);
    await updateDoc(publicUserDocRef, {
      posts: arrayUnion(postDocRef),
    });

    return postDocRef.id;
  } catch (error) {
    console.error("Error adding post: ", error);
    throw error;
  }
}

export async function deletePost(userId: string, postId: string) {
  try {
    // Reference to the post's document
    const postDocRef = doc(db, "posts", postId);

    // Delete the post document
    await deleteDoc(postDocRef);
    console.log("Post deleted successfully");

    // Now remove this post's DocumentReference from the publicUsers document's posts field
    const publicUserDocRef = doc(db, "publicUsers", userId);
    await updateDoc(publicUserDocRef, {
      posts: arrayRemove(postDocRef),
    });

    console.log("Post reference removed from public user document");

    return true;
  } catch (error) {
    console.error("Error deleting post: ", error);
    throw error;
  }
}

export function getPublicUserReference(address: string): DocumentReference {
  // Reference to the public user's document using the unique identifier
  const publicUserDocRef = doc(db, "publicUsers", address);
  return publicUserDocRef;
}

export async function fetchUserData(userRef: DocumentReference<PublicInfo>) {
  try {
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      // The user data exists in the database
      const userData = userSnapshot.data();
      return userData; // This is your user's data
    } else {
      // No such document!
      console.log("No user found with this reference!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data: ", error);
    throw error;
  }
}

export async function getPublicUser(
  address: string
): Promise<PublicInfo | null> {
  const pubRef = getPublicUserReference(
    address
  ) as DocumentReference<PublicInfo>;
  return fetchUserData(pubRef);
}

import { orderBy } from "firebase/firestore";
import { testNet } from "@/xrplservice/storage";

export async function getAllPostIds(): Promise<string[]> {
  try {
    // Reference to the posts collection
    const postsCollectionRef = collection(db, "posts");

    // Create a query against the collection, ordering by creation date descending
    const q = query(postsCollectionRef, orderBy("created", "desc"));

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Map each document snapshot to its document ID
    const postIds = querySnapshot.docs.map((docSnapshot) => docSnapshot.id);

    return postIds;
  } catch (error) {
    console.error("Error fetching post IDs: ", error);
    throw error; // Rethrow the error for further handling if necessary
  }
}
export async function getPosts(
  userID: string
): Promise<{ post: Post; id: string }[]> {
  try {
    // Reference to the user's public profile document
    const publicUserDocRef = doc(db, "publicUsers", userID);

    // Get the user document snapshot
    const userDocSnapshot = await getDoc(publicUserDocRef);

    // Initialize an array to hold the posts
    const posts: { post: Post; id: string }[] = [];

    // Check if the user's document exists and has posts
    if (userDocSnapshot.exists() && userDocSnapshot.data().posts) {
      // Get the array of post references from the document
      const postRefs: DocumentReference[] = userDocSnapshot.data().posts;

      // Fetch each post document and add to the posts array
      for (const postRef of postRefs) {
        const postSnapshot = await getDoc(postRef);
        if (postSnapshot.exists()) {
          const post = postSnapshot.data() as Post;
          posts.push({ post: post, id: postSnapshot.id });
        }
      }
    } else {
      // Handle the case where the user's document or posts do not exist
      console.log("No such user or no posts available!");
    }

    return posts;
  } catch (error) {
    console.error("Error fetching posts: ", error);
    throw error; // Rethrow the error for further handling if necessary
  }
}

export async function getPost(postID: string): Promise<Post> {
  try {
    const postRef = doc(db, "posts", postID);
    const postSnapshot = await getDoc(postRef);

    if (postSnapshot.exists()) {
      // The post data exists in the database
      const postData = postSnapshot.data();
      return postData as Post; // Cast the data to Post type and return it
    } else {
      // No post found for this reference
      console.log("No post found with this reference!");
      return Promise.reject("No post found with this reference!");
    }
  } catch (error) {
    console.error("Error fetching post data: ", error);
    return Promise.reject(error);
  }
}

export const likePost = async (
  postID: string,
  address: string
): Promise<void> => {
  try {
    console.log(`Account ${address} liking ${postID}`);
    const accountDocRef = doc(db, "likedPosts", address);

    const docSnap = await getDoc(accountDocRef);

    if (docSnap.exists()) {
      await setDoc(
        accountDocRef,
        {
          posts: arrayUnion(postID),
        },
        { merge: true }
      );
    } else {
      await setDoc(accountDocRef, {
        posts: [postID],
      });
    }

    console.log(`Account ${address} liked ${postID}`);
  } catch (error) {
    console.error("Error liking post:", error);
    throw error;
  }
};

export async function unlikePost(
  postID: string,
  address: string
): Promise<void> {
  try {
    const accountDocRef = doc(db, "likedPosts", address);

    // Remove the postID from the 'posts' array for the user's liked posts
    await updateDoc(accountDocRef, {
      posts: arrayRemove(postID),
    });

    console.log(`Account ${address} unliked ${postID}`);
  } catch (error) {
    console.error("Error unliking post:", error);
    throw new Error("Error unliking post");
  }
}

export async function hasLikedPost(
  postID: string,
  address: string
): Promise<boolean> {
  try {
    const postRef = doc(db, "likes", postID);

    // Retrieve the document
    const docSnap = await getDoc(postRef);

    if (docSnap.exists()) {
      // Check if the address is in the 'following' array
      const data = docSnap.data();
      return data.addresses.includes(address);
    } else {
      // If the document does not exist, the post has not been liked by this address
      return false;
    }
  } catch (error) {
    console.error("Error checking if post is liked:", error);
    throw new Error("Failed to check if post is liked");
  }
}

export async function getLikeCount(postID: string): Promise<number> {
  try {
    const likeCountRef = doc(db, "likeCount", postID);

    // Retrieve the document
    const docSnap = await getDoc(likeCountRef);

    if (docSnap.exists()) {
      // Return the like count
      const data = docSnap.data();
      return data.count;
    } else {
      // If the document does not exist, the post has no likes
      return 0;
    }
  } catch (error) {
    console.error("Error retrieving like count:", error);
    throw new Error("Error retrieving like count");
  }
}

export const makeComment = async (
  commentData: Comment,
  address: string
): Promise<void> => {
  // make comment hash
  const dataString = JSON.stringify(commentData);
  const dataHash = CryptoJS.MD5(dataString).toString();

  try {
    console.log(`Account ${address} commenting ${commentData.text}`);
    const commentDocRef = doc(db, "comments", dataHash);

    const docSnap = await getDoc(commentDocRef);

    if (!docSnap.exists()) {
      await setDoc(commentDocRef, commentData);
      console.log("Post added successfully");
    }
    console.log(
      `Account ${address} commented on ${commentData.postID} with '${commentData.text}'`
    );
  } catch (error) {
    console.error("Error liking post:", error);
    throw error;
  }
};

export const getComment = async (
  commentID: string
): Promise<Comment | null> => {
  try {
    const commentRef = doc(db, "comments", commentID);
    const commentSnapshot = await getDoc(commentRef);

    if (commentSnapshot.exists()) {
      // The post data exists in the database
      const commentData = commentSnapshot.data();
      return commentData as Comment; // Cast the data to Post type and return it
    } else {
      // No post found for this reference
      console.log("No post found with this reference!");
      return Promise.reject("No post found with this reference!");
    }
  } catch (error) {
    console.error("Error fetching post data: ", error);
    return Promise.reject(error);
  }
};

export async function getCommentCount(postID: string): Promise<number> {
  try {
    const likeCountRef = doc(db, "commentCount", postID);

    // Retrieve the document
    const docSnap = await getDoc(likeCountRef);

    if (docSnap.exists()) {
      // Return the like count
      const data = docSnap.data();
      return data.count;
    } else {
      // If the document does not exist, the post has no likes
      return 0;
    }
  } catch (error) {
    console.error("Error retrieving like count:", error);
    throw new Error("Error retrieving like count");
  }
}

export const fetchCommentsForPost = async (
  postID: string,
  lowerIndex: number,
  upperIndex: number,
  excludeID: string | null = null
): Promise<PubUserComment[]> => {
  const postCommentsRef = doc(db, "postComments", postID);
  try {
    const postCommentsDoc = await getDoc(postCommentsRef);
    if (!postCommentsDoc.exists()) {
      console.log("No comments found for this post.");
      return [];
    }

    let commentIDs: string[] = postCommentsDoc.data().comments;

    if (excludeID) {
      commentIDs = commentIDs.filter((id) => id !== excludeID);
    }
    if (commentIDs.length === 0 || lowerIndex >= commentIDs.length) {
      return []; // Return empty if no comments or lowerIndex is beyond available comments
    }

    // Calculate the indices for slicing the array to fetch recent comments
    const totalComments = commentIDs.length;
    const sliceStart = Math.max(totalComments - upperIndex, 0);
    const sliceEnd = totalComments - lowerIndex;

    // Fetch each comment based on the comment IDs in reverse order
    const commentsPromises = commentIDs
      .slice(sliceStart, sliceEnd)
      .reverse()
      .map(async (commentID) => {
        const commentRef = doc(db, "comments", commentID);
        const commentSnap = await getDoc(commentRef);
        const comment = commentSnap.data() as Comment;
        const pubInfo = await getPublicUser(comment.address);

        if (pubInfo) {
          if (pubInfo.deactivated) {
            return null;
          }
          return {
            publicInfo: pubInfo,
            comment: comment,
            id: commentID,
          };
        }
        return null;
      });

    // Resolve all promises to get the array of comments
    const commentsBatch = await Promise.all(commentsPromises);
    const output = commentsBatch.filter(
      (
        puCom: { publicInfo: PublicInfo; comment: Comment; id: string } | null
      ) => {
        return puCom !== null;
      }
    ) as PubUserComment[];
    return output;
  } catch (error) {
    console.error("Error fetching comments:", error);
    throw error;
  }
};

export async function deleteComment(postId: string, commentId: string) {
  try {
    // Reference to the comment's document
    const commentDocRef = doc(db, "comments", commentId);

    // Delete the comment document
    await deleteDoc(commentDocRef);
    console.log("Comment deleted successfully");

    // // Now remove this comment's DocumentReference from the post document's comments field
    // const postDocRef = doc(db, "posts", postId);
    // await updateDoc(postDocRef, {
    //   comments: arrayRemove(commentDocRef),
    // });

    console.log("Comment reference removed from post document");

    return true;
  } catch (error) {
    console.error("Error deleting comment: ", error);
    throw error;
  }
}

export const fetchNotifications = async (
  address: string,
  lowerIndex: number,
  upperIndex: number
): Promise<Notification[]> => {
  console.log("userCredential", userCredential.value);
  console.log("");
  try {
    const notificationsRef = doc(db, "notifications", address);

    const notificationsDoc = await getDoc(notificationsRef);
    if (!notificationsDoc.exists()) {
      console.log("No notifications found for this address.");
      return [];
    }

    const data = notificationsDoc.data();

    if (!data || !data.notifications) {
      console.log("No notifications data found.");
      return [];
    }

    // Calculate the indices for slicing the array to fetch recent notifications
    const totalNotifications = data.notifications.length;
    const sliceStart = Math.max(totalNotifications - upperIndex, 0);
    const sliceEnd = totalNotifications - lowerIndex;

    // Slice the array to get the required notifications
    const latestNotifications: Notification[] = data.notifications
      .slice(sliceStart, sliceEnd)
      .reverse();

    return latestNotifications;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};

export const newNotificationsFalse = async (address: string): Promise<void> => {
  try {
    const notificationsRef = doc(db, "notifications", address);

    await updateDoc(notificationsRef, {
      newNotifications: false,
    });
  } catch (error) {
    console.error("Error setting newNotifications to false:", error);
    throw error;
  }
};

export const getNewNotifications = async (
  address: string
): Promise<boolean> => {
  try {
    const notificationsRef = doc(db, "notifications", address);

    const notificationsDoc = await getDoc(notificationsRef);
    if (!notificationsDoc.exists()) {
      return false;
    }

    const data = notificationsDoc.data();

    if (!data || !data.newNotifications) {
      return false;
    }

    return data.newNotifications;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};

import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
// Define the interface for the NFT attributes
interface NFTAttribute {
  value: string;
  trait_type: string;
}

// Define the interface for the request payload
interface MintNFTRequest {
  receiverAddress: string;
  name: string;
  description: string;
  attributes: NFTAttribute[];
}

// Function to prepare and send the minting request
export async function mintStatNFT(
  receiverAddress: string,
  name: string,
  description: string,
  attributes: NFTAttribute[]
): Promise<{ transactionHash: string; tokenURI: string }> {
  const data: MintNFTRequest = {
    receiverAddress,
    name,
    description,
    attributes,
  };

  const mintNFTfunc = httpsCallable(functions, "mintNFT");

  const result = await mintNFTfunc(data);
  const dataOut = result.data as { transactionHash: string; tokenURI: string };
  return dataOut;
}
