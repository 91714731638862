import type {
  AccountInfoResponse,
  AccountTxRequest,
  AccountTxResponse,
  AccountTxTransaction,
  Client,
  Transaction,
  TransactionMetadata,
} from "xrpl";
import type AccountRoot from "xrpl/dist/npm/models/ledger/AccountRoot";
import { appMinBlock } from "./storage";

export const PUBLIC_SERVER = "wss://xrplcluster.com";
export const TEST_SERVER = "wss://s.altnet.rippletest.net:51233";

export const SERVICE_URL = process.env.VUE_APP_BACKEND_URL;
export const DEFAULT_TESTNET = SERVICE_URL === "http://localhost:8080";

export function getClient(testNet: boolean): Client {
  const connectionTimeout = 20000;
  if (testNet) {
    // @ts-ignore
    return new xrpl.Client(TEST_SERVER, { connectionTimeout });
  }
  // @ts-ignore
  return new xrpl.Client(PUBLIC_SERVER, { connectionTimeout });
}

export async function getCurrentAccountInfo(
  address: string,
  testNet: boolean
): Promise<AccountRoot> {
  const req = {
    id: 2,
    command: "account_info",
    account: address,
  };

  const client = getClient(testNet);

  await client.connect();
  const response: AccountInfoResponse = await client.request(req);
  const accountData: AccountRoot = response.result.account_data;
  accountData.Balance = (parseInt(accountData.Balance) / 1000000).toString();
  client.disconnect();
  return accountData;
}

type TransactionCache = {
  cachedTransList: AccountTxTransaction[];
  latestBlock: number;
};

/**
 * Fetch transactions and the latest block index from the local storage.
 * @param address The account or wallet address to fetch transactions for.
 * @returns An object containing the cached transactions and the latest block index.
 */
function getTransactionsFromCache(address: string): TransactionCache {
  const cacheData = localStorage.getItem(address);

  // If there's no cached data for the given address, return an empty array for transactions
  // and appMinBlock for the latest block
  if (!cacheData) {
    return {
      cachedTransList: [],
      latestBlock: Number(appMinBlock.value),
    };
  }

  return JSON.parse(cacheData);
}

/**
 * Save transactions and the latest block index to the local storage.
 * @param address The account or wallet address to associate the data with.
 * @param transactions The list of transactions to cache.
 * @param latestBlock The latest block or ledger index.
 */
function saveTransactionsToCache(
  address: string,
  transactions: AccountTxTransaction[],
  latestBlock: number
): void {
  const cacheData: TransactionCache = {
    cachedTransList: transactions,
    latestBlock: latestBlock,
  };

  localStorage.setItem(address, JSON.stringify(cacheData));
}

async function fetchTransactions(
  address: string,
  testNet: boolean
): Promise<AccountTxTransaction[]> {
  const transactionCache = getTransactionsFromCache(
    address + testNet.toString()
  );

  const latestBlock = transactionCache.latestBlock;
  const cachedTransList = transactionCache.cachedTransList;

  const client = getClient(testNet);
  await client.connect();
  const req: AccountTxRequest = {
    // id: 2,
    command: "account_tx",
    account: address,
    ledger_index_min: latestBlock,
    limit: 1000,
    // forward: false,
  };

  const response: AccountTxResponse = await client.request(req);
  const transList: AccountTxTransaction[] = response.result.transactions;
  client.disconnect();

  const newLatestBlock = response.result.ledger_index_max;

  const cachedHashes = cachedTransList.map((tran) => {
    return tran.tx?.hash;
  });

  const uniqueTransList = transList.filter((tran) => {
    return !cachedHashes.includes(tran.tx?.hash);
  });

  const newTransList = uniqueTransList.concat(cachedTransList);

  // ? You can turn this on to cache transactions
  // if (transList.length) {
  //   saveTransactionsToCache(
  //     address + testNet.toString(),
  //     newTransList,
  //     newLatestBlock
  //   );
  // }

  return newTransList;
}

export async function getTransactions<T>(
  address: string,
  testNet: boolean,
  tType: string
): Promise<{ tx: T; meta: TransactionMetadata }[]> {
  const transList: AccountTxTransaction[] = await fetchTransactions(
    address,
    testNet
  );

  if (transList.length === 0) {
    return [];
  }
  const transListFiltered = transList.filter((trans: AccountTxTransaction) => {
    const meta = trans.meta as TransactionMetadata;
    return meta.TransactionResult === "tesSUCCESS";
  });

  const outList: { tx: T; meta: TransactionMetadata }[] = [];
  for (const trans of transListFiltered) {
    const meta = trans.meta as TransactionMetadata;
    if (!trans.tx) {
      continue;
    }
    const tx: Transaction = trans.tx;

    if (tx.TransactionType === tType) {
      const tTx = tx as any as T;
      outList.unshift({ tx: tTx, meta: meta });
    }
  }
  return outList;
}

export function convertHexToString(hex: string): string {
  let str = "";
  for (let i = 0; i < hex.length; i += 2) {
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }
  return str;
}

export function convertStringToHex(str: string): string {
  let hex = "";
  for (let i = 0; i < str.length; i++) {
    hex += str.charCodeAt(i).toString(16);
  }
  return hex;
}

export function xrpToDrops(xrp: number): string {
  return (xrp * 1000000).toString();
}
