import { createApp } from "vue";
import App from "./App.vue";
import "@/scss/style.scss";
import router from "./router/router";
import vuetify from "./plugins/vuetify";
import VueFeather from "vue-feather";
import { createPinia } from "pinia";
import "./registerServiceWorker";
import { createHead } from "@unhead/vue";

const head = createHead();

createApp(App)
  .component(VueFeather.name ? VueFeather.name : "", VueFeather)
  .use(router)
  .use(createPinia())
  .use(vuetify)
  .use(head)
  .mount("#app");
